









































import {Component, Prop, Vue} from "vue-property-decorator";
import RouteNames from "../../router/RouteNames";
import UserDataDTO from "@/dto/UserDataDTO";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import FileUpload from "@/components/common/FileUpload.vue";
import UserDTO from "@/dto/UserDTO";
import {excelAccept, pdfAccept} from "@/constants/FileConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PayrollService from "@/services/payroll/PayrollService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CompanyDTO from "@/dto/company/CompanyDTO";
import Workspace from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import Workspaces from "@/state/Workspaces";

const Auth = namespace("Auth");
const AppModule = namespace("App");

@Component({
	components: {FileUpload}
})
export default class SelectBusinessPaystubAction extends Vue {

	private DOCUMENT_ACCEPT = pdfAccept

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private uploadAction = false;

	private stamp = false;

	private successful = false;

	private message = "";

	mounted() {
		console.log(Workspaces.getCurrent)
	}

	closeModal() {
		this.$modal.hideAll()
	}

	goToPaystubs() {
		this.$router.push({
			name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
			params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.PAYSTUB}
		});
		this.closeModal();
	}

	uploadPaystubs(){
		this.uploadAction = true;
	}

	private stampPhrase() {
		this.stamp = true
		this.uploadPaystubs()
	}

	onFilesUploaded(files: FileMetaDTO[]) {
		if (this.stamp) {
			this.message = "";
			this.startLoading();
			PayrollService.stamp(files.map(f => f.id)).then(
				res => {
					this.successful = true;
					this.message = "Stamped";
					res.data.forEach(it => this.$docUtils.download(it))
				},
				error => {
					this.successful = false;
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
					this.stopLoading()
				}
			)
		} else {
			this.message = "";
			this.startLoading();
			PayrollService.savePaystubs(files.map(f => f.id), Workspaces.getCurrent.id).then(
				success => {
					this.successful = true;
					this.message = "Uploaded";
					this.stopLoading()
				},
				error => {
					this.successful = false;
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
					this.stopLoading()
				}
			).then(() => this.stopLoading());
		}
	}
}
