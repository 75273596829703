



































































import {Component, Vue} from "vue-property-decorator";
import RouteNames from "@/router/RouteNames";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import {namespace} from "vuex-class";
import SelectBusinessPaystubAction from "@/components/payroll/SelectBusinessPaystubAction.vue";
import SelectBusinessPayrollDetailsAction from "@/components/payroll/SelectBusinessPayrollDetailsAction.vue";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import ImportPayrollModal from "@/components/payroll/ImportPayrollModal.vue";
import {COMPANY_ID} from "@/constants/Paths";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class BusinessPayroll extends Vue {

	@AppModule.State
	private isMobile!: boolean;

	@Auth.Getter
	private hasAdministrativePrivileges!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	goToSickLeaves() {
		this.$router.push({
			name: RouteNames.PAYROLL_COMPANY_SICK_LEAVE_LIST,
			params: {companyId: this.$wss.getCurrent.id.toString()}
		});
	}

	goToVacations() {
		this.$router.push({
			name: RouteNames.PAYROLL_COMPANY_VACATION_LIST,
			params: {companyId: this.$wss.getCurrent.id.toString()}
		});
	}

	goToPaystubs() {
		if (!this.hasAdministrativePrivileges) {
			this.$router.push({
				name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
				params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.PAYSTUB}
			});
		} else {
			this.$modal.show(
				SelectBusinessPaystubAction,
				{},
				{
					width: "50%",
					height: "auto",
					scrollable: true,
				}
			);
		}
	}

	openImportModal() {
		this.$modal.show(
			ImportPayrollModal,
		)
	}

	goToPolicies() {
		this.$router.push({
			name: RouteNames.PAYROLL_COMPANY_POLICY,
			params: {
				companyId: this.$wss.getCurrent.id.toString()
			}
		})
	}

	goToPayrollReports() {
		this.$router.push({
			name: RouteNames.PAYROLL_BUSINESS_REPORTS,
			params: {
				companyId: this.$wss.getCurrent.id.toString()
			}
		})
	}

	goToProjects() {
		this.$router.push({name: RouteNames.PAYROLL_COMPANY_PROJECT, params: {companyId: this.$route.params.companyId}});
	}

	goToEmployees() {
		this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: this.$route.params.companyId}});
	}

	goToContractors() {
		this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: this.$route.params.companyId}});
	}

	goToForeignContractors() {
		this.$router.push({name: RouteNames.HR_FOREIGN_CONTRACTORS, params: {companyId: this.$route.params.companyId}});
	}

	goToForms() {
		this.$router.push({name: RouteNames.PAYROLL_BUSINESS_FORMS, params: {companyId: this.$route.params.companyId}});
	}

	goToReports() {
		this.$router.push({name: RouteNames.PAYROLL_BUSINESS_REPORTS, params: {companyId: this.$route.params.companyId}});
	}

	goToDetails() {
		if (!this.hasAdministrativePrivileges) {
			this.$router.push({
				name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
				params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.DETAILS}
			});
		} else {
			this.$modal.show(
				SelectBusinessPayrollDetailsAction,
				{},
				{
					width: "50%",
					height: "auto",
					scrollable: true,
				}
			);
		}
	}


}

