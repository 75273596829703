











import {Vue, Component} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PayrollService from "@/services/payroll/PayrollService";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import {processError} from "@/utils/ComponentUtils";

@Component({
	components: {SingleFileHolder}
})
export default class ImportPayrollModal extends Vue {

	private file: FileMetaDTO | null = null

	private importPayroll() {
		Application.startLoading()
		PayrollService.importPayroll(this.file!.id).then(
			() => {
				Notifications.success("File has been successfully processed!");
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}
}
